:root {
    // Background color
    --primary-bg-color: #f9f9fa;
    --cell-bg-color: #ffffff;
    --btn-bg-color: #d4d5d6;
    // Text color
    --primary-text-color: #707070;
    --bid-text-color: #1ea0f0;
    --ask-text-color: #d91660;
    --back01-text-color: #222222;
    --back02-text-color: #15202b;
    --back03-text-color: #313131;
    // Border color
    --cell-border-color: #e7e7e7;
}
