/*
 * "NotoSansJP" licensed under the SIL Open Font License 1.1
 * https://fonts.google.com/specimen/Noto+Sans+JP
 */
@font-face {
    font-family: "NotoSansJPBold";
    src: local("Noto Sans CJK JP Regular"), local("NotoSansJP-Regular"),
        url("../fonts/NotoSansJP-Bold.otf") format("opentype");
}
/*
 * "NotoSansJP" licensed under the SIL Open Font License 1.1
 * https://fonts.google.com/specimen/Noto+Sans+JP
 */
@font-face {
    font-family: "NotoSansJPRegular";
    src: local("Noto Sans JP Regular"), local("NotoSansJP-Regular"),
        url("../fonts/NotoSansJP-Regular.otf") format("opentype");
}

/*
 * Copyright 2011 Google Inc. All Rights Reserved.
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@font-face {
    font-family: "RobotoBold";
    src: local("Roboto-Bold"), url("../fonts/Roboto-Bold.ttf") format("truetype");
}
/*
 * Copyright 2011 Google Inc. All Rights Reserved.
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@font-face {
    font-family: "RobotoMedium";
    src: local("Roboto-Medium"), url("../fonts/Roboto-Medium.ttf") format("truetype");
}
/*
 * Copyright 2011 Google Inc. All Rights Reserved.
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@font-face {
    font-family: "RobotoRegular";
    src: local("Roboto-Regular"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}
/*
 * Copyright 2011 Google Inc. All Rights Reserved.
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@font-face {
    font-family: "RobotoCondensedBold";
    src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"),
        url("../fonts/RobotoCondensed-Bold.ttf") format("truetype");
}
/*
 * Copyright 2011 Google Inc. All Rights Reserved.
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@font-face {
    font-family: "RobotoCondensedRegular";
    src: local("Roboto Condensed Regular"), local("RobotoCondensed-Regular"),
        url("../fonts/RobotoCondensed-Regular.ttf") format("truetype");
}