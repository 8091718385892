.cell-currency {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--cell-border-color);
    cursor: pointer;
    &.hover-t {
        &:hover {
            background-color: rgba(121, 121, 121, 0.7);
            .stock-above {
                background-color: rgba(121, 121, 121, 0.7);
                background: none;
            }
            .stock-bellow {
                background-color: rgba(121, 121, 121, 0.7);
                background: none;
            }
        }
    }
    .stock-content {
        width: 79.45%;
        border-left: 1px solid transparent;
        .stock-above {
            position: relative;
            display: flex;
            align-items: center;
            background-color: var(--cell-bg-color);
            justify-content: space-between;
            padding: 8px 0 3px;
            .pl-46 {
                padding-left: 46px;
            }
            .pr-66 {
                padding-right: 21px;
            }
            .spread {
                position: absolute;
                display: flex;
                justify-content: center;
                top: 19px;
                right: 43.5%;
            }
        }
        .stock-bellow {
            display: flex;
            align-items: center;
            background-color: var(--cell-bg-color);
            height: 26px;
            .swap-number {
                display: flex;
                align-items: center;
            }
            .ask-swap {
                width: 28.42%;
                .swap-title {
                    padding-left: 50.5px;
                }
            }
            .bid-swap {
                width: 19.47%;
            }
            .previous-close-price {
                width: 20.53%;
            }
            .high-price {
                width: 15.44%;
                display: flex;
                justify-content: flex-end;
                padding-top: 3px;
                .label-number {
                    width: 71px;
                    display: flex;
                    justify-content: flex-start;
                }
            }
            .low-price {
                width: 16.14%;
                display: flex;
                justify-content: flex-end;
                padding-top: 3px;
                .label-number {
                    width: 59px;
                    padding-right: 21px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .pl-4 {
                padding-left: 4px;
            }
            .mt-3 {
                margin-top: 3px;
            }
        }
    }
}
