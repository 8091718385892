.label {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    white-space: normal;
    word-break: break-all;
    width: auto;
    font-family: "NotoSansJPRegular";
    &.primary {
        color: var(--primary-text-color);
    }
    &.black01 {
        color: var(--back01-text-color);
    }
    &.black02 {
        color: var(--back02-text-color);
    }
    &.black03 {
        color: var(--back03-text-color);
    }
    &.bid {
        color: var(--bid-text-color);
    }
    &.ask {
        color: var(--ask-text-color);
    }
}
@for $i from 11 through 55 {
    .fs-#{$i} {
        font-size: #{$i}px;
    }
    .lh-#{$i} {
        line-height: #{$i}px;
    }
}
