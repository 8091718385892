.currency-pair {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20.55%;
    .currency-pair-img {
        position: relative;
        padding-top: 4px;
        .left-flag {
            position: absolute;
            top: 4px;
            left: 5px;
            z-index: 1;
            width: 42px;
            height: 42px;
        }
        .right-flag {
            margin-left: 29px;
            width: 42px;
            height: 42px;
        }
    }
    .currency-pair-name {
        margin-top: -7px;
    }
}
