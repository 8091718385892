.modal-style {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    z-index: 1010;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.overlay-style {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1010;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: var(--primary-bg-color);
}
.content-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding-top: 70px;
    img {
        height: 42px;
        width: 42px;
    }
}
.message {
    margin-top: 20px;
    margin-bottom: 90px;
    white-space: pre-line;
    text-align: center;
}
