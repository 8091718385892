.rate-page {
    .title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--primary-bg-color);
        .currency-pair-title {
            width: 20.55%;
            padding: 1.5px 0;
            .label {
                display: flex;
                align-items: center;
            }
        }
        .currency-pair-unit {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 79.45%;
            height: 39px;
            border-left: 1px solid transparent;
            .label {
                margin-top: 2px;
            }
            .bid-unit {
                margin-left: 114px;
            }
            .spread-unit {
                position: absolute;
                display: flex;
                justify-content: center;
                top: 7px;
                right: 43.5%;
            }
            .ask-unit {
                margin-right: 57px;
            }
        }
    }
}
