.rate-number {
    display: flex;
    align-items: center;
    .bid-arrow {
        width: 13px;
        img {
            height: 12px;
            width: 12px;
        }
    }
    .label {
        display: flex;
        justify-content: flex-end;
        width: 108px;
        min-width: 108px;
        margin-left: 13px;
    }
}
